import React, { useState, useEffect } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import Banner from "../components/sharedComponents/Banner";
import Spinner from "../components/sharedComponents/Spinner";
import { useSelector } from "react-redux";
import { GoArrowLeft } from "react-icons/go";
import Auth from "../utils/auth";
export default function CashPaymentConfirmation() {
  const username = useSelector((state) => state.titular);
  const titular = username ? username : localStorage.getItem("cash_titular");
  const cashAmount = localStorage.getItem("cash_amount");
  const comission = localStorage.getItem("comission");
  const lastFourDigits = localStorage.getItem("lastFourDigits");
  const reference = localStorage.getItem("cash_id");
  const email = localStorage.getItem("email");
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true); // Initialize your variable
  const handleContinueClick = async (e) => {
    e.preventDefault();
    navigate("/home");
  };

  const expireAt = localStorage.getItem("expirationDate"); // 2024-10-05 16:13"
  const date = new Date(expireAt);

  const day = date.getDate();
  const year = date.getFullYear();
  const hour = date.getHours();
  const minute = date.getMinutes();

  const monthNames = [
    "enero",
    "febrero",
    "marzo",
    "abril",
    "mayo",
    "junio",
    "julio",
    "agosto",
    "septiembre",
    "octubre",
    "noviembre",
    "diciembre",
  ];
  const month = monthNames[date.getMonth()];

  const formattedDate = `${day} de ${month} de ${year} a las ${hour}:${
    minute < 10 ? "0" : ""
  }${minute}`;
  useEffect(() => {
    if (!Auth.loggedIn()) {
      navigate("/home");
      return;
    }
    const timer = setTimeout(() => {
      setIsLoading(false); // Set your variable to false after 5 seconds
    }, 5000);

    // Cleanup function to clear the timer if the component is unmounted before the 5 seconds are up
    return () => clearTimeout(timer);
  }, []);

  // {isLoading ? (
  //       <Spinner />
  //     ) : ()}

  return (
    <section className="font-gotham min-h-screen place-items-center place-content-start grid phone:grid-cols-1 laptop:grid-cols-8">
      {/* <div className="phone:row-span-1 laptop:col-span-2 flex items-center justify-center bg-red-500 laptop:h-screen phone:h-1/8 overflow-hidden">
        <img
          src={protectMovilImg}
          alt="home"
          className="laptop:w-full h-full phone:h- object-cover "
        />
      </div> */}
      {/* <div className="absolute laptop:top-0 phone:top-[45vw] laptop:left-[26vw] phone:left-[2vw] laptop:pt-5 phone:pt-5">
        <button onClick={() => navigate("/home")}>
          <GoArrowLeft className="text-primary laptop:h-8 laptop:w-8 phone:h-6 phone:w-6" />
        </button>
      </div> */}

      <Banner />
      {/* <div
        className="object-cover phone:row-span-1 laptop:col-span-2 flex items-center justify-center bg-red-500 phone:h-1/4  bg-top-quarter"
        style={{ backgroundImage: `url(${protectMovilImg})` }}
      /> */}

      {isLoading ? (
        <Spinner />
      ) : (
        <div className="flex flex-col  justify-center item-center phone:row-span-4  laptop:col-span-6 py-10 ">
          <h1 className="text-2xl  text-primary font-semibold phone:px-8 text-center mb-8">
            ¡Estás muy cerca de proteger tu equipo!
          </h1>

          <p className="text-xl text-[#6D80A4] phone:px-8 text-center mb-8 laptop:w-[50vw]">
            Acude a cualquier tienda OXXO con la referencia que recibirás por
            correo electrónico y realiza el pago en efectivo.
          </p>

          {/* <div className="flex justify-center items-center text-center pt-4 font-thin px-6 laptop:w-[40vw]">
            <p className="text-xl text-[#6D80A4]">
              Acude a cualquier tienda OXXO con la referencia que recibirás por
              correo electrónico y realiza el pago en efectivo.
            </p>
          </div> */}

          <div className="flex flex-col justify-center laptop:items-start phone:items-start">
            <div className="flex laptop:flex-row phone:flex-col justify-start items-start text-left pt-4 font-thin px-6 phone:px-9">
              <p className="laptop:text-xl phone:text-base text-[#6D80A4] font-thin">
                <span className="font-bold">Nombre: </span>
              </p>
              <p className="text-[#6D80A4] laptop:text-xl phone:text-base pl-1">
                {" "}
                {titular}
              </p>
            </div>
            <div className="flex laptop:flex-row phone:flex-col justify-start items-start text-left pt-4 font-thin px-6 phone:px-9">
              <p className="laptop:text-xl phone:text-base text-[#6D80A4] font-thin">
                <span className="font-bold"> Correo electrónico: </span>
              </p>
              <p className="text-[#6D80A4] laptop:text-xl phone:text-base pl-1">
                {email}
              </p>
            </div>
            <div className="flex laptop:flex-row phone:flex-col justify-start items-start text-left pt-4 font-thin px-6 phone:px-9">
              <p className="laptop:text-xl phone:text-base text-[#6D80A4] font-bold">
                {" "}
                <span>Monto a pagar: </span>{" "}
              </p>
              <p className="pl-1 text-[#6D80A4] laptop:text-xl phone:text-base ">
                {"   "}${cashAmount}{" "}
                <span className="text-xs text-[#6D80A4]">
                  (+ ${comission} de comisión)
                </span>
              </p>
            </div>
            <div className="flex laptop:flex-row phone:flex-col justify-start items-start text-left pt-4  font-thin px-6 phone:px-9">
              <p className="laptop:text-xl phone:text-base text-[#6D80A4] font-thin">
                <span className="font-bold">Referencia: </span>
              </p>
              <p className="text-[#6D80A4] laptop:text-xl phone:text-base pl-1">
                {reference}
              </p>
            </div>
            <div className="flex laptop:flex-row phone:flex-col justify-start items-start text-left pt-4  font-thin px-6 phone:px-9">
              <p className="laptop:text-xl phone:text-base text-[#6D80A4] font-thin">
                <span className="font-bold laptop:text-xl phone:text-base">
                  Fecha límite de pago:{" "}
                </span>{" "}
              </p>
              <p>
                <span className="laptop:text-xl phone:text-base text-[#6D80A4] pl-1">
                  {formattedDate} hrs.
                </span>
              </p>
            </div>
          </div>
          <div className="pt-8 text-sm laptop:text-base laptop:pt-[2rem] laptop:pb-[1rem] text-center">
            <button
              onClick={handleContinueClick}
              className=" font-thin bg-orange-500 hover:bg-orng text-white font-bold py-2 px-4 rounded-md md:py-3 md:px-6 laptop:w-64 phone:w-48"
            >
              Aceptar
            </button>
          </div>
        </div>
      )}
    </section>
  );
}
