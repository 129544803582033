import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Banner from "../components/sharedComponents/Banner";
import CustomErrorModal from "../components/sharedComponents/CustomErrorModal";
import { GoArrowLeft } from "react-icons/go";
import { useDispatch } from "react-redux";
import Spinner from "../components/sharedComponents/Spinner";
import { post } from "aws-amplify/api";
import nocert from "../assets/images/nocert-15.png";
import Auth from "../utils/auth";
import warningLogo from "../assets/images/warningLogo.png";
export default function CertificateEntry() {
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [notFoundErrorMessage, setNotFoundErrorMessage] = useState("");
  const [triggerError, setTriggerError] = useState(false);
  const [imeiState, setImeiState] = useState("");
  const [emailFormState, setEmailFormState] = useState({
    phoneNumber: "",
    email: "",
  });
  const [focused, setFocused] = useState(false);
  const [phoneErrorMsg, setPhoneErrorMsg] = useState("");
  const [imeiErrorMsg, setImeiErrorMsg] = useState("");
  const [emailErrorMsg, setEmailErrorMsg] = useState("");
  const [imeiMethod, setImeiMehtod] = useState(true);
  const [numberMehtod, setNumberMethod] = useState(false);
  const [certificates, setCertificates] = useState([]);
  const [enableBtn, setEnableBtn] = useState(true);

  async function getCertificate() {
    // console.log("validating!!!");
    try {
      const restOperation = post({
        apiName: "ppmrestapi",
        path: "/getinsurance",
        options: {
          body: {
            imei: imeiState ? imeiState : "",
            phone: emailFormState.phoneNumber ? emailFormState.phoneNumber : "",
            email: emailFormState.email ? emailFormState.email : "",
          },
        },
      });

      const { body } = await restOperation.response;
      const response = await body.json();

      console.log("getinsurance R ", response);

      if (response.code === "0") {
        if (imeiState && response.data.length == 0) {
          setNotFoundErrorMessage(
            "No se encontró un certificado para el IMEI ingresado. Por favor, verifícalo e intenta de nuevo"
          );
        } else if (emailFormState.phoneNumber && response.data.length == 0) {
          setNotFoundErrorMessage(
            "No se encontró un certificado para el número Telcel o correo electrónico ingresados. Por favor, verifícalos e intenta de nuevo"
          );
        }
        if (response.data.length > 1) {
          localStorage.setItem("email", emailFormState.email);
          localStorage.setItem("phone", emailFormState.phoneNumber);
          localStorage.setItem("certificates", JSON.stringify(response.data));
          dispatch({ type: "SET_CERTIFICATES", payload: response.data });
          Auth.severalCertificateLogin(response.token, response.data);
          // return navigate("/elige-certificado");
        } else if (response.data.length === 0) {
          return;
        } else {
          let serviceUrlObject = JSON.parse(response.data[0].serviceUrl.body);
          let serviceUrl = serviceUrlObject.data;

          localStorage.setItem("serviceName", response.data[0].serviceName);
          localStorage.setItem("email", response.data[0].email);
          localStorage.setItem("imei", response.data[0].imei);
          localStorage.setItem("downloadUrl", serviceUrl);
          Auth.uniqueCertificateLogin(response.token);
          // return navigate("/certificado");
        }
      }
      // console.log("POST call succeeded");
      // console.log(response);
    } catch (e) {
      console.log("POST call failed: ", JSON.parse(e.response.body));
      const errorBody = JSON.parse(e.response.body);
      console.log("Error body: ", errorBody);

      if (
        errorBody.code === "30" ||
        errorBody.code === "001" ||
        errorBody.code === "002" ||
        errorBody.code === "003"
      ) {
        imeiState
          ? setNotFoundErrorMessage(
              "No se encontró un certificado para el IMEI ingresado. Por favor, verifícalo e intenta de nuevo"
            )
          : setNotFoundErrorMessage(
              "No se encontró un certificado para el número Telcel o correo electrónico ingresados. Por favor, verifícalos e intenta de nuevo"
            );
        setTriggerError(true);
      } else {
        navigate("/home");
      }
    }
  }

  //Old getCertificate request
  // const getCertificate = async (e) => {
  //   console.log("Getting certificate...");
  //   try {
  //     const copyBody = {
  //       imei: imeiState,
  //       phone: emailFormState,
  //       email: emailFormState,
  //     };
  //     console.log("copyBody", copyBody);
  //     const url =
  //       "https://zpryy1jgve.execute-api.us-east-1.amazonaws.com/dev/getinsurance";
  //     const response = await fetch(url, {
  //       method: "POST",
  //       headers: {
  //         "Content-Type": "application/json",
  //       },
  //       body: JSON.stringify({
  //         imei: imeiState ? imeiState : "",
  //         phone: emailFormState.phoneNumber ? emailFormState.phoneNumber : "",
  //         email: emailFormState.email ? emailFormState.email : "",
  //       }),
  //     });

  //     const data = await response.json();
  //     console.log("SUBS INFO ", data);

  //     // localStorage.setItem("serviceName", data.serviceName);
  //     // return navigate("/certificateConfirmation");

  //     if (data.code === "001" || data.code === "002" || data.code === "003") {
  //       setNotFoundErrorMessage("No se encontró un certificado para el IMEI.");
  //     } else if (data.code === "0") {
  //       localStorage.setItem("serviceName", data.data[0].serviceName);
  //       localStorage.setItem("email", emailFormState.email);
  //       dispatch({ type: "SET_POLICIES", payload: data.data });
  //       if (data.data.length > 1) {
  //         return navigate("/elige-certificado");
  //       } else {
  //         return navigate("/descarga-certificado");
  //       }
  //     }
  //   } catch (error) {
  //     throw new Error("An error occurred while validating IMEI", error);
  //   }
  // };

  const handleCheckbox = (e) => {
    //e.preventDefault();
    setImeiMehtod(!imeiMethod);
    setNumberMethod(!numberMehtod);
  };

  const handleImeiChange = (e) => {
    const { name, value } = e.target;
    console.log("IMEI Change ", value);
    setImeiErrorMsg("");
    setPhoneErrorMsg("");
    setEmailErrorMsg("");
    setEmailFormState(
      Object.keys(emailFormState).reduce(
        (acc, key) => ({ ...acc, [key]: "" }),
        {}
      )
    );
    setImeiState(value);
  };

  const handleNumberEmailChange = (e) => {
    const { name, value } = e.target;
    setImeiState("");
    setImeiErrorMsg("");
    name === "phoneNumber" ? setPhoneErrorMsg("") : setEmailErrorMsg("");
    setEmailFormState({ ...emailFormState, [name]: value });
  };

  const validation = (name, value) => {
    let phoneRegex = /[0-9]{10}/;
    let imeiRegex = /[0-9]{15}/;
    let emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;

    if (name === "phone") {
      console.log("Phone Validation ", value);
      return phoneRegex.test(value);
    } else if (name === "imei") {
      console.log("IMEI Validation ", value);
      return imeiRegex.test(value);
    } else if (name === "email") {
      console.log("Email Validation ", value);
      return emailRegex.test(value);
    }
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    if (
      imeiState !== "" ||
      (emailFormState.phoneNumber !== "" && emailFormState.email !== "")
    ) {
      if (imeiState !== "") {
        //console.log("IMEI State ", imeiState);
        setIsLoading(true);
        validation("imei", imeiState)
          ? getCertificate().finally(() => setIsLoading(false))
          : setImeiErrorMsg("Porfavor ingresa un IMEI válido");
      } else if (
        emailFormState.phoneNumber !== "" &&
        emailFormState.email !== ""
      ) {
        if (!validation("phone", emailFormState.phoneNumber)) {
          console.log("phone error triggered");
          setPhoneErrorMsg("Porfavor ingresa un número válido");
        } else if (!validation("email", emailFormState.email)) {
          console.log("email error triggered");
          setEmailErrorMsg("Porfavor ingresa un correo válido");
        } else {
          setIsLoading(true);
          getCertificate().finally(() => setIsLoading(false));
          // console.log("Form Submitted");
          // return navigate("/certificateConfirmation");
        }
      }
      // if(imeiState !== ""){
      //   handleImeiSubmit(e);
      // }else{
      //   handleNumberFormSubmit(e);
      // }
    }
  };

  const handleNumberFormSubmit = async (e) => {
    try {
      e.preventDefault();
      console.log("Submit Triggered");
      console.log("Form State ", emailFormState);
      if (
        !emailFormState.phoneNumber ||
        !validation("phone", emailFormState.phoneNumber)
      ) {
        console.log("phone error triggered");
        setPhoneErrorMsg("Plase provide a valid phone number");
      } else if (
        !emailFormState.email ||
        !validation("email", emailFormState.email)
      ) {
        console.log("email error triggered");
        setEmailErrorMsg("Plase provide a valid email");
      } else {
        getCertificate();
        // console.log("Form Submitted");
        // return navigate("/certificateConfirmation");
      }
    } catch (err) {
      console.log(err);
      throw new Error("An error occurred while submitting the form");
    } finally {
      console.log("Finally block");
      return;
    }
  };

  const handleEmailFormSubmit = async (e) => {
    try {
      e.preventDefault();
      console.log("Submit Triggered");
      console.log("Form State ", emailFormState);
      if (
        !emailFormState.phoneNumber ||
        !validation("phone", emailFormState.phoneNumber)
      ) {
        console.log("phone error triggered");
        setPhoneErrorMsg("Plase provide a valid phone number");
      } else if (
        !emailFormState.email ||
        !validation("email", emailFormState.email)
      ) {
        console.log("email error triggered");
        setEmailErrorMsg("Plase provide a valid email");
      } else if (Number(emailFormState.phoneNumber) !== 5537339881)
        setNotFoundErrorMessage(
          "No se encontró un certificado para el número Telcel o correo electrónico ingresados."
        );
      else {
        console.log("Form Submitted");
        return navigate("/certificateConfirmation");
      }
    } catch (err) {
      console.log(err);
      throw new Error("An error occurred while submitting the form");
    } finally {
      console.log("Finally block");
      return;
    }
  };

  // const inputs = [
  //   {
  //     id: "1",
  //     type: "text",
  //     name: "phoneNumber",
  //     label: "Número telcel",
  //     errorMessage: "Should contain at least 10 digits",
  //     required: true,
  //     pattern: "[0-9]{10}",
  //   },
  //   {
  //     label: "IMEI",
  //     name: "imei",
  //     id: "1",
  //     type: "text",
  //     errorMessage: "Should contain at least 18 digits",
  //     required: true,
  //   },
  // ];

  // const [imeiState, setImeiState] = useState("");
  // const [emailFormState, setEmailFormState] = useState({
  //   phoneNumber: "",
  //   email: "",
  // });

  const handleInputBlur = (e) => {
    const { name, value } = e.target;
    if (name === "phoneNumber") {
      validation("phone", value)
        ? setPhoneErrorMsg("")
        : setPhoneErrorMsg("Por favor, proporciona un número válido.");
    } else if (name === "imei") {
      validation("imei", value)
        ? setImeiErrorMsg("")
        : setImeiErrorMsg("Por favor, proporciona un IMEI válido.");
    } else if (name === "email") {
      validation("email", value)
        ? setEmailErrorMsg("")
        : setEmailErrorMsg("Por favor, proporciona un correo válido.");
    }
    // setFormState({ ...formState, [name]: value });
  };

  useEffect(() => {
    if (
      validation("phone", emailFormState.phoneNumber) &&
      validation("email", emailFormState.email)
    ) {
      setEnableBtn(false);
    } else if (validation("imei", imeiState)) {
      setEnableBtn(false);
    } else {
      setEnableBtn(true);
    }
    // if(emailFormState.phoneNumber && emailFormState.email){
    //   console.log("Option 1")
    // }
    // else if (imeiState){
    //   console.log("Option 2")
    // }
    // setEnableBtn(false);

    // if (
    //   validation("phone", formState.phoneNumber) &&
    //   validation("imei", formState.imei)
    // ) {
    //   setEnableBtn(false); // enable the button
    // } else {
    //   setEnableBtn(true); // disable the button
    // }
  }, [emailFormState.phoneNumber, emailFormState.email, imeiState]);
  return (
    <section className="relative font-gotham min-h-screen place-items-center place-content-start grid phone:grid-cols-1 laptop:grid-cols-8">
      <div className="absolute laptop:top-0 phone:top-[45vw] laptop:left-[26vw] phone:left-[2vw] laptop:pt-2 phone:pt-5 tablet:pt-8">
        <button
          onClick={() => {
            return navigate("/home");
          }}
        >
          <GoArrowLeft className="text-primary laptop:h-8 laptop:w-8 phone:h-6 phone:w-6" />
        </button>
      </div>
      <Banner />
      {isLoading ? (
        <Spinner />
      ) : (
        <form
          onSubmit={handleFormSubmit}
          className=" flex flex-col phone:row-span-4  laptop:col-span-6 laptop:py-20 phone:pt-10 laptop:px-[20vh] phone:px-[10vh] phone:py-[15vh] laptop:py-0"
        >
          <div className="flex justify-center">
            <h1 className="laptop:text-2xl tablet:text-2xl phone:text-lg text-primary text-center font-semibold  phone:py-4 phone:mb-1 laptop:py-0  laptop:px-0">
              Ingresa los siguientes datos para recibir tu certificado en el
              correo electrónico que registraste al contratar el servicio:
            </h1>
          </div>
          {/* IMEI handler section */}
          <div className="grid laptop:grid-cols-2 gap-4">
            <h1 className="laptop:py-10 phone:py-0 text-center laptop:px-[15vh] text-primary font-thin text-lg">
              Tengo mi IMEI
            </h1>
            <h1 className="phone:hidden laptop:block laptop:py-10 text-center laptop:px-[10vh] text-primary font-thin text-lg">
              No tengo mi IMEI
            </h1>
          </div>

          <div className="grid laptop:grid-cols-2 phone:grid-cols-1 gap-4">
            <div className="flex flex-col justify-start items-center">
              <div className="flex flex-col justify-start items-start text-left pt-10">
                <div className="mb-2 flex flex-row justify-center items-center">
                  <div>
                  <label
                  htmlFor="name"
                  className="laptop:text-lg phone:text-md text-[#6D80A4] mb-2"
                >
                 IMEI
                <p class="font-serif phoneS:text-[6.5px] phone:text-[6.5px] tablet:text-[6.7px] laptop:text-[6px] desktop:text-[6px] laptopL:text-[8px] desktopL:text-[11px]  laptop:inline laptopL:inline italic laptopL:ml-2 laptop:ml-2">(Para obtenerlo marca *#06# o encuéntralo en la caja de tu
                  smartphone.)</p>
                </label>
                  </div>

                  {/* <div className="relative inline-block group  ml-1">
                    <span className="flex items-center justify-center w-3 h-3 bg-[#6D80A4] text-white rounded-full cursor-pointer text-[0.5rem]">
                      i
                    </span>
                    <div className="absolute hidden group-hover:block -top-1/2 left-full ml-2 laptop:w-[22vw] phone:w-56 p-2 bg-tooltip text-primary text-xs rounded shadow-lg">
                      Para obtenerlo marca *#06# o encuéntralo en la caja de tu
                      smartphone.
                    </div>
                  </div> */}
                </div>

                <input
                  type="text"
                  id="imei"
                  name="imei"
                  placeholder="15 dígitos"
                  onChange={handleImeiChange}
                  onBlur={handleInputBlur}
                  value={imeiState}
                  className={`px-3 text-[#6D80A4] laptop:w-[20vw] phone:w-56 h-10 border-2 ${
                    imeiErrorMsg ? "border-red-500" : "border-gray-300"
                  } rounded-md px-2`}
                />
                {imeiErrorMsg ? (
                  <div className="flex flex-row items-center justify-center pt-2">
                    <img src={warningLogo} alt="" className="w-4 h-4 mr-2" />
                    <span className="w-72 text-red-500 text-xs text-left">
                      {imeiErrorMsg}
                    </span>
                  </div>
                ) : null}
              </div>
            </div>

            {/* // Divisor */}
            <div className="laptop:hidden border-b border-opacity-25 border-primary w-full mt-6"></div>
            <div className="absolute phone:hidden laptop:block border-l border-primary border-1 border-opacity-25 h-64 ml-[26.5vw]"></div>

            {/* Number and email handler section */}
            <div>
              <h1 className="laptop:hidden text-center laptop:py-10 phone:py-0 phone:pt-8 laptop:px-[10vh] text-primary font-thin text-lg">
                No tengo mi IMEI
              </h1>
              <div className="flex flex-col justify-center items-center">
                <div className="flex flex-col justify-start items-start text-left pt-10">
                  <label
                    htmlFor="name"
                    className="text-base text-[#6D80A4] mb-2"
                  >
                    Número Telcel
                  </label>
                  <input
                    type="text"
                    id="phoneNumber"
                    name="phoneNumber"
                    placeholder="10 dígitos"
                    onChange={handleNumberEmailChange}
                    onBlur={handleInputBlur}
                    value={emailFormState.phoneNumber}
                    className={`px-3 text-[#6D80A4] laptop:w-[20vw] phone:w-56 h-10 border-2 pr-6 ${
                      phoneErrorMsg ? "border-red-500" : "border-gray-300"
                    } rounded-md px-2`}
                  />
                  {/* </div> */}
                  {phoneErrorMsg ? (
                    <div className="flex flex-row items-center justify-center pt-2">
                      <img src={warningLogo} alt="" className="w-4 h-4 mr-2" />
                      <span className="w-72 text-red-500 text-xs text-left">
                        {phoneErrorMsg}
                      </span>
                    </div>
                  ) : null}
                </div>
                <div className="flex flex-col justify-start items-start text-left pt-10">
                  <label
                    htmlFor="name"
                    className="text-base text-[#6D80A4] mb-2"
                  >
                    Correo electrónico
                  </label>
                  <input
                    type="text"
                    id="email"
                    name="email"
                    placeholder="ejemplo@mail.com"
                    onChange={handleNumberEmailChange}
                    onBlur={handleInputBlur}
                    value={emailFormState.email}
                    className={`px-3 text-[#6D80A4] laptop:w-[20vw] phone:w-56 h-10 border-2 pr-6 ${
                      emailErrorMsg ? "border-red-500" : "border-gray-300"
                    } rounded-md px-2`}
                  />
                  {/* </div> */}
                  {emailErrorMsg ? (
                    <div className="flex flex-row items-center justify-center pt-2">
                      <img src={warningLogo} alt="" className="w-4 h-4 mr-2" />
                      <span className="w-72 text-red-500 text-xs text-left">
                        {emailErrorMsg}
                      </span>
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
          <div className="flex justify-center">
            <button
              type="submit"
              disabled={enableBtn}
              onClick={() => setNotFoundErrorMessage("")}
              className={`w-[50vw] ${
                enableBtn
                  ? "bg-orange-300 cursor-not-allowed"
                  : "bg-orng hover:bg-orange-700 "
              } laptop:w-[20vw] mt-12 laptop:mt-20 text-white 
              focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-md px-5 py-2.5 text-center`}
            >
              Aceptar
            </button>
          </div>
        </form>
      )}
      <CustomErrorModal
        isVisible={notFoundErrorMessage}
        onClose={() => setNotFoundErrorMessage("false")}
      >
        <div className="font-gotham flex flex-col items-center py-5 px-5 laptop:px-7 text-left">
          {/* <h3 className="mb-4 text-lg font-medium text-primary text-center">
              Selecciona los siguientes datos para generar tu CURP
            </h3> */}
          <div className="flex justify-center mb-4 mt-4">
            <img src={nocert} alt="" className="text-center w-10 h-10" />
          </div>

          <h1 className="text-center text-lg text-[#6D80A4] font-semibold mb-2">
            Certificado no disponible
          </h1>
          <div className="laptop:w-96 phone:w-64 laptop:w-[20vw] rounded-full h-[0.3rem] bg-[#F45753] bg-opacity-25 mb-3"></div>
          <div className="flex justify-center tems-center laptop:w-[22vw] phone:w-64 ">
            <p className="  mb-4 text-base font-regular text-[#6D80A4] text-center">
              No se encontró un certificado para el IMEI ingresado. Por favor,
              verifícalo e intenta de nuevo.
            </p>
          </div>

          <div
            className="space-y-6 flex flex-row justify-center items-center"
            action="#"
          >
            <button
              onClick={() => {
                setTriggerError(false);
                navigate("/home");
              }}
              className="laptop:w-[10vw] phone:w-[50vw] tablet:w-[20vw] text-white bg-[#6D80A4] hover:bg-opacity-75 
              focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium
              rounded-lg text-base px-5 phone:px-3 py-1 text-center"
            >
              Aceptar
            </button>
          </div>
        </div>
      </CustomErrorModal>
    </section>
  );
}
