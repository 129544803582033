import React, { Children } from "react";

export default function TermsModal({ isVisible, onClose, color, children }) {
  if (!isVisible) return null;
  const handleClose = (e) => {
    if (e.target.id === "wrapper") onClose();
  };
  return (
    <div
      onClick={handleClose}
      className=" fixed inset-0 bg-black bg-opacity-25 backdrop-blur-sm flex justify-center items-center"
      id="wrapper"
    >
      <div className="laptop:ml-[25vw] laptop:w-1/3 phone:w-80 tablet:w-1/2 flex flex-col">
        {/* <button
          onClick={() => onClose()}
          className="text-white text-xl place-self-end"
        >
          X
        </button> */}

        <div className="bg-white  rounded-md">
          <div
            className={`w-[auto] rounded-t-md h-[0.5rem] bg-[#EAC360]`}
          ></div>
          {children}
        </div>
      </div>
    </div>
  );
}
