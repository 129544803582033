import React, { useState, useRef, useEffect } from "react";
import "../../assets/css/video.css";
import VideoDesktop from "../../assets/video/PMAK_2024_1920x1080.mp4";
import VideoPhone from "../../assets/video/PMAK_2024_1080x1080pxs.mp4";
import { FaVolumeMute, FaVolumeUp, FaRedoAlt  } from "react-icons/fa";
import { MdOutlineReplay } from "react-icons/md";

const VideoOverlay = () => {
  const [isVideoVisible, setIsVideoVisible] = useState(true);
  const [videoSrc, setVideoSrc] = useState("");
  const [isMuted, setIsMuted] = useState(true);
  const [isReady, setIsReady] = useState(false);
  const videoRef = useRef(null);
  const [isFinished, setIsFinished] = useState(false);
  const [videoProgress, setVideoProgress] = useState(0);
  const [isPaused, setIsPaused] = useState(false);

  const enableSound = () => {
    if (videoRef.current) {
      videoRef.current.muted = false;
      setIsMuted(false);
    }
  };

  const muteSound = () => {
    if (videoRef.current) {
      videoRef.current.muted = true;
      setIsMuted(true);
    }
  };

  const closeVideo = (event) => {
    event.stopPropagation();
    setIsVideoVisible(false);
  };

  useEffect(() => {
    setIsVideoVisible(true);
    setIsReady(true);
  }, []);

  const handleVideoEnd = () => {
    setIsFinished(true);
    setIsPaused(true);
  };

  const setVolume = () => {
    if (videoRef.current) {
      videoRef.current.volume = 0.1;
    }
  };

  const handleProgressChange = (e) => {
    if (videoRef.current) {
      const newTime = (e.target.value / 100) * videoRef.current.duration;
      videoRef.current.currentTime = newTime;
      setVideoProgress(e.target.value);

      if (newTime < videoRef.current.duration) {
        setIsFinished(false);
      }

      if (isPaused) {
        videoRef.current.play();
        setIsPaused(false);
      }
    }
  };

  const handlePlayAgain = () => {
    if (videoRef.current) {
      videoRef.current.currentTime = 0;
      videoRef.current.play();
      setIsFinished(false);
      setIsPaused(false);
      setVolume();
    }
  };

  useEffect(() => {
    const updateVideoSource = () => {
      if (window.innerWidth <= 1023) {
        setVideoSrc(VideoPhone);
      } else {
        setVideoSrc(VideoDesktop);
      }
    };

    updateVideoSource();

    window.addEventListener("resize", updateVideoSource);

    return () => {
      window.removeEventListener("resize", updateVideoSource);
    };
  }, []);

  setVolume();

  return (
    <>
      {isVideoVisible && isReady && (
        <div
          className="fixed inset-0 bg-black bg-opacity-70 flex justify-center items-center z-50"
        >
          <div className="relative max-w-4xl max-h-4xl phone:max-w-xs phone:max-h-xs phoneS:max-w-[271px] phoneS:max-h-[400px] tablet:max-w-lg tablet:max-h-[600px] laptop:max-w-2xl desktopL:max-w-5xl">
            <div className="flex mb-2">
              <button
                onClick={closeVideo}
                type="button"
                className="bg-white text-black font-bold text-xl phone:text-sm phoneS:text-xs rounded-full phone:w-6 phone:h-6 phoneS:w-5 phoneS:h-5 desktopL:w-13 desktopL:h-13 w-10 h-10 flex justify-center items-center hover:bg-opacity-75 focus:outline-none ml-auto"
              >
                X
              </button>
            </div>
            <video
              ref={videoRef}
              className="w-full h-full rounded-lg"
              autoPlay
              muted={isMuted}
              loop={false}
              onEnded={handleVideoEnd}
              src={videoSrc}
              onTimeUpdate={() => {
                if (videoRef.current) {
                  setVideoProgress((videoRef.current.currentTime / videoRef.current.duration) * 100);
                }
              }}
            ></video>

            <div
              onClick={isMuted ? enableSound : muteSound} // Mantenemos el onClick aquí en el ícono
              className="absolute bottom-4 right-4 p-2 bg-black bg-opacity-30 text-white rounded-full cursor-pointer hover:bg-opacity-75"
            >
              {isMuted ? <FaVolumeMute size={24} /> : <FaVolumeUp size={24} />}
            </div>

            <input
              type="range"
              min="0"
              max="100"
              value={videoProgress}
              onChange={handleProgressChange}
              className="absolute bottom-2 left-1/2 transform -translate-x-1/2 w-3/4 bg-transparent appearance-none h-2 rounded-full"
              style={{
                background: `linear-gradient(to right, rgba(255, 255, 255, 0.5) ${videoProgress}%, rgba(255, 255, 255, 0.2) 0%)`,
                backdropFilter: 'blur(8px)',
                borderRadius: '10px',
              }}
            />

            {isFinished && (
              <div className="absolute bottom-10 left-1/2 transform -translate-x-1/2">
                <button
                  onClick={handlePlayAgain}
                  className="bg-white text-black font-bold px-6 py-2 rounded-full hover:bg-opacity-75 focus:outline-none"
                >
                  <MdOutlineReplay size={24} />
                </button>
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default VideoOverlay;
