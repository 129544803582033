import React, { useState } from "react";
import { useSelector } from "react-redux";
export default function SmartphoneData() {
  const imei = localStorage.getItem("subscriberIMEI");
  const brand = localStorage.getItem("brand");
  const model = localStorage.getItem("phoneModel"); //useSelector((state) => state.model);

  return (
    <div className="flex flex-row bg-[#fff7e9] text-primary">
      <div className="flex flex-col items-start mb-2">
        <p className="mx-[1rem] mt-2">IMEI:</p>
        <p className="mx-[1rem] mt-2">Marca:</p>
        <p className="mx-[1rem] mt-2">Modelo:</p>
      </div>
      <div className="flex flex-col items-start mb-2">
        <p className="mx-[1rem] mt-2">{imei}</p>
        <p className="mx-[1rem] mt-2">{brand}</p>
        <p className="mx-[1rem] mt-2">{model}</p>
      </div>
    </div>
  );
}
